import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface traceIdState {
  lastTraceId: string;
  traceIds: string[];
  clickedTraceId: string;
}
// valorizzo stato iniziale
const initialState: traceIdState = {
  lastTraceId: "",
  traceIds: [],
  clickedTraceId: "",
};

export const traceIdSlice = createSlice({
  name: "traceId",
  initialState,
  reducers: {
    setLastTraceId: (state, action: PayloadAction<string>) => {
      if (state.lastTraceId !== action.payload) {
        state.lastTraceId = action.payload;
      }
    },
    addTraceId: (state, action: PayloadAction<string>) => {
      state.traceIds.push(action.payload);
    },
    removeTraceId: (state, action: PayloadAction<string>) => {
      state.traceIds = state.traceIds.filter(
        (traceId) => traceId !== action.payload
      );
    },
    setClickedTraceId: (state, action: PayloadAction<string>) => {
      state.clickedTraceId = action.payload;
    },
    resetClickeTraceId: (state) => {
      state.clickedTraceId = "";
    },
    resetAllTraceId: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setLastTraceId,
  addTraceId,
  removeTraceId,
  setClickedTraceId,
  resetClickeTraceId,
  resetAllTraceId,
} = traceIdSlice.actions;

export default traceIdSlice.reducer;
